.App {
  height: 800px;
  width: 100%;
  background-color: rgb(67, 67, 67);
}

.header {
  width: 100%;
  height: 50px;
  background-color: black;
  text-align: center;
  color: azure;
  align-content: center;
  font-size: 25px;
}
.container {
  width: 100%;
  height: 800px;
  margin-top: 2%;
  color: azure;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
}

.player-container{
  margin-top: 4px;
  height: 60px;
  margin-left: 1%;
  width: 98%;
  background-color: rgb(67, 67, 67);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  
}
.player-container:hover{
  background-color: rgb(122, 122, 122);
  cursor: pointer;
}

.player-1{
  width: 40%;
  height: 60px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
}
.img{
  height: 60px;
  width: 60px;
}
.p1{
  height: 60px;
  width: 76%;
  display: flex;
}
.p1-1{
  height: 60px;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  font-size: 90%;
  color: rgb(172, 172, 172);
}
.p-num{
  margin-top: 4px;
  margin-bottom: 6px;
}
.uzb{
  height: 24px;
  width: 100%;
  border-radius: 3px;

}
.p1-2{
  height: 60px;
  width: 50%;
  display: flex;
  flex-direction: column;
}
.p1-2-name{
  margin-top: 4px;
  height: 30px;
  width: 100%;
  align-content: center;
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 90%;

}
.p1-2-position{
  height: 20px;
  width: 100%;
  align-content: center;
  text-align: center;
  color: rgb(172, 172, 172);
  font-size: 70%;
}
.player-2{
  height: 60px;
  width: 60%;
  display: flex;
  flex-direction: row;
 
}
.stats-holder{
  height: 60px;
  width: 14%;
  display: flex;
  flex-direction: column;
}
.ovr-text{
  margin-top: 5px;
  width: 100%;
  height: 20px;
  font-size: 80%;
  text-align: center;
  color: rgb(172, 172, 172);
}
.ovr-rating{
  width: 100%;
  height: 30px;
  font-size: 90%;
  text-align: center;
}
.rate-text{
  width: 100%;
  margin-top: 2px;
  height: 12px;
  font-size: x-small;
  text-align: center;
  color: rgb(172, 172, 172);
}
.rate-num{
  width: 100%;
  height: 15px;
  font-size: smaller;
  text-align: center;
  align-content: center;
}